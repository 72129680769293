.news-content {
	padding: 32px;
}

.news-image {
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.news-list-page {
	padding: 32px;
}