.cng-brand {
	text-decoration: none;
	color: black;
	font-size: 1.25em;
}

.cng-brand:link {
	color: black;
}

.cng-brand:hover {
	color: black;
}

.cng-brand > .cng-logo {
	height: 32px;
}

.cng-main-color {
	color: #2980b9;
}

.navbar {
	padding: 16px;
}

.nav-link {
	height: 30px;
	padding-left: 1rem !important;
	padding-right: 1rem !important;
	padding-top: 0px;
	padding-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-link.is-enabled {
	color: #2980b9 !important;
	font-weight: bold;
	border-radius: 15px;
}

.social-container {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.inline-flex {
	display: inline-flex;
}

.inline-flex.vcentered {
	align-items: center;
}

.navbar-nav > .nav-item {
	display: flex;
	align-items: center;
	justify-content: center;
}