.home-background {
	height: 300px;
	width: 100%;
	object-fit: cover;
	object-position: 50% 75%;
	filter: brightness(0.8)
}

.home-headline {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.home-headline > h1 {
	font-size: 80px;
	color: white;
	text-align: center;
} 

.home-headline-content {
	font-size: 5vw;
	text-align: center;
	color: #ecf0f1 !important
}

@media only screen and (max-width: 1024px) {
	.home {
	  background: none !important;
	}
}

.home-container {
	padding: 26px;
	background-color: transparent;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
} 

.scrolldown-button {
	font-size: 30px;
	color: lightgray !important;
	opacity: 0.8;
	animation-name: bounce-2;
	animation-timing-function: ease;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	cursor: pointer;

}

.scrolldown-button:hover {
	color: lightgray !important;
	opacity: 1;
}


@keyframes bounce-2 {
	0%   { transform: translateY(0); }
	50%  { transform: translateY(-10px); }
	100% { transform: translateY(0); }
}

.wave {
	transform: rotate(180deg);
	height: 50px;
	width: 100%;
}