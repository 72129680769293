.social-button {
	width: 30px;
	height: 30px;
	border-radius: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2em;
	margin: 8px;
	border: 0;
}

.social-button:link {
	text-decoration: none;
}

.social-button.facebook {
	background-color: white;
	color: #4267B2;
}

.social-button.facebook:hover {
	background-color: #4267B2;
	color: white;
}

.social-button.instagram {
	background-color: white;
	color: #C13584;
}

.social-button.instagram:hover {
	background-color: #C13584;
	color: white;
}

.social-button.webcam {
	background-color: white;
	color: #34495e;
	font-size: 1em;
}

.social-button.webcam:hover {
	background-color: #34495e;
	color: white;
}