.pricing-pages {
	padding: 32px;
}

.pricing-table {
	border: solid 1px lightgray;
}

.thead-cng {
	background-color: #2980b9;
	color: white;
}

.pricing-pages tbody tr:hover {
	background-color: lightgray;
}