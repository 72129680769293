.widget-container {
    border: solid 1px darkgray;
    background-color: #ecf0f1;
}

.widget-container > .header {
    background-color: #f1c40f;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    color: black;
} 

.widget-container > .body {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}