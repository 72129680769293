.documents {
    padding: 32px;
}

.item-row {
    text-decoration: none;
    font-weight: bolder;
    transition: background-color ease-in-out 0.050s;
}

.item-row:hover {
    background-color: #ecf0f1;
    color: #3498db;
    cursor: pointer;
}

.table a
{
    display:block;
    text-decoration:none;
}
